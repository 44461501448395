<template>
  <div id="app">
    <div class="heading">
      <div class="caption">
        <span class="border">Hi, I'm Greg Billings</span>
        <p style="height: 55px;"></p>
        <span class="border">Developer | Architect | Tinkerer</span>
      </div>
    </div>
    <v-app>
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 style="text-align:center;">About Me</h1>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="4" class="justify-center align-center">
          <v-img
            max-height="950"
            max-width="550"
            src="./assets/polaroids.png"
          ></v-img>
        </v-col>
        <v-col cols="12" md="8">
          <p>Hi, my name is Greg. If you read my LinkedIn profile, you'd know that I'm </p>
          
          <blockquote class="quote" style="padding-bottom: 15px;">"an experienced "full-stack" Software Development Assoc Director with proven skills in customer relationship building, technical requirements gathering, agile development practices, mentoring, cloud development, SRE practices, architecture, testing, and more. I pride myself on leading development teams in the delivery of quality products on time and within budget that impact the lives of hundreds of developers, thousands of clinicians, and millions of patients."</blockquote>

          <p>I'm much more than those two sentences though. When I was in 5th grade I learned HTML and that was that, 
          I listed Computer Engineer on the "what you want to be when you grow up" assignment. Being able to turn a blank page 
          on a screen into something interactive and useful was a magical experience and I've been doing it ever since.</p>

          <p>However, I wanted to be more than just a coder. I wanted to have the business acumen to turn real problems into technical solutions.
          I wanted to meet with customers and listen to their challenges and work with developers to bring solutions to life. I also work on driving innovation and creativity in 
          organizations that I'm a part of. Constant growth and self-reflection are at my core and I use them to help develop myself and those around
          me.</p>

          <p>Thank you so much for taking the time to view my portfolio and please feel free to contact me, even if just to say hello!</p>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center divider">
        <v-col cols="12" md="6">
          <hr />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h1 style="text-align:center;">Experience</h1>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="d-none d-md-flex">
        <v-col cols="12" md="11">
          <v-timeline>
            <v-timeline-item v-for="(item, index) in positions" :key="index" color="#495464" small>
              <v-card>
                <v-list-item three-line>
                  <v-list-item-avatar>
                    <v-img
                      max-height="35"
                      max-width="35"
                      :src="item.logo"
                    >
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="headline">
                      <span class="font-weight-light">{{ item.company }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ item.timespan }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ item.position }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-card-text class="white text--primary">
                  <p class="work-summary" v-html="item.summary"></p>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="d-flex d-md-none">
        <v-col cols="12" sm="12" v-for="(item, index) in positions" :key="index" >
          <v-card>
            <v-list-item three-line>
              <v-list-item-avatar>
                <v-img
                  max-height="35"
                  max-width="35"
                  :src="item.logo"
                >
                </v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  <span class="font-weight-light">{{ item.company }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.timespan }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ item.position }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-card-text class="white text--primary">
              <p class="work-summary" v-html="item.summary"></p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center divider">
        <v-col cols="12" md="6">
          <hr />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h1 style="text-align:center;">My Causes</h1>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="3">
          <flip-card>
            <template slot="front">
              <v-card min-height="430" class="d-flex justify-center align-center">
                <v-img class="cause-image" src="./assets/causes/eff.png"></v-img>
              </v-card>
            </template>
            <template slot="back">
              <v-card min-height="430" class="d-flex justify-center align-center cause-text">
                <div><p>The EFF is a leading non-profit that defends digital privacy, free speech, and innovation.</p><p>To me, protecting the internet and our privacy is a noble cause. 
                The EFF works to educate our law makers and ensures the internet is a place that encourages innovation and the free flowing of ideas. <a target="_blank" href="https://supporters.eff.org/donate/pu20--WB">Donate Here</a></p></div>
              </v-card>
            </template>
          </flip-card>
        </v-col>
        <v-col cols="12" md="3">
          <flip-card>
            <template slot="front">
              <v-card min-height="430" class="d-flex justify-center align-center">
                <v-img class="cause-image" src="./assets/causes/trevor.png"></v-img>
              </v-card>
            </template>
            <template slot="back">
             <v-card min-height="430" class="d-flex justify-center align-center cause-text">
                <div><p>The Trevor Project is the leading national organization providing crisis intervention and suicide prevention services to LGBTQ young people under 25.</p><p>I support the Trevor Project so they can continue to connect and provide support to our young LGBTQ people so they can make a positive impact on our world. <a target="_blank" href="https://give.thetrevorproject.org/give/63307/#!/donation/checkout?c_src=EOY20&c_src2=headerdonatebutton">Donate Here</a></p></div>
              </v-card>
            </template>
          </flip-card>
        </v-col>
        <v-col cols="12" md="3">
          <flip-card>
            <template slot="front">
              <v-card min-height="430" class="d-flex justify-center align-center">
                <v-img class="cause-image" src="./assets/causes/gpa.jpeg"></v-img>
              </v-card>
            </template>
            <template slot="back">
              <v-card min-height="430" class="d-flex justify-center align-center  cause-text">
                <div><p>Greyhound Pets of America works collaboratively with adopters and perspective adopters, with rescue, humane and other greyhound groups and the racing industry to find homes for retired racers.</p><p>
                I adopted my dog, Blaze, in 2015 shortly after I moved into my first apartment and began working at my first job. Blaze has been a loyal best frield to me and a wonderful first dog. I donate to GPAN to help other 
                retired racers find their forever homes. <a target="_blank" href="http://www.gpanashville.org/index.php">Donate Here</a></p></div>
              </v-card>
            </template>
          </flip-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center divider">
        <v-col cols="12" md="6">
          <hr />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h1 style="text-align:center;">Contact Me</h1>
        </v-col>
      </v-row>
      <v-row class="d-flex align-center justify-center">
        <v-col cols="12" md="6">
          <p class="text-center">While I am currently employeed, I do enjoy the occasional side project! Please get in touch with me!</p>
        </v-col>
      </v-row>
      <v-row class="d-flex align-center justify-center">
        <v-col cols="12" md="2" class="d-flex justify-center text-center">
          <v-card class="d-flex justify-center text-center contact" target="_blank" href="mailto:greg@backcountrydev.com">
            <v-icon size="100" color="#e76f51">mdi-at</v-icon>
          </v-card>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-center text-center">
          <v-card class="d-flex justify-center text-center contact" target="_blank" href="https://www.linkedin.com/in/gregorybillings/">
            <v-icon size="100" color="#e76f51">mdi-linkedin</v-icon>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    </v-app>
</div>
</template>

<script>

import FlipCard from './components/FlipCard'

export default {
  name: 'App',
  components: {
    'flip-card': FlipCard
  },
  data: () => ({
      positions: [
        {
          logo: require('./assets/logos/veson.jpg'),
          company: 'Veson Nautical',
          timespan: 'Jan 2025 – Present',
          position: 'Associate Director, Software Engineering',
          summary: ``
        },
        {
          logo: require('./assets/logos/veson.jpg'),
          company: 'Veson Nautical',
          timespan: 'July 2022 – Jan 2025',
          position: 'Software Engineering Manager',
          summary: ``
        },
        {
          logo: require('./assets/logos/hca.jpg'),
          company: 'HCA Healthcare',
          timespan: 'Mar 2021 – July 2022',
          position: 'Manager of Developer Platforms',
          summary: `<p>Manager of 13 exceptional talented full-time software developers across 5 value streams: UI, Mobile, API Enterprise Platform, Cloud & Product Automation, and Enterprise Communications Platform.</p>
          
          <p>My day-to-day role consists of building strong customer partnerships, setting priority and direction, leading agile ceremonies (such as stand-ups, sprint planning, retrospectives, and demos), meeting with stakeholders, and producing roadmaps and demonstrations. I also am responsible in working with our corporate teams around contracting and procurement, human resources, architecture, and operations.</p>`
        },
        {
          logo: require('./assets/logos/hca.jpg'),
          company: 'HCA Healthcare',
          timespan: 'Nov 2019 – Mar 2021',
          position: 'Lead Software Architect & Manager',
          summary: `Architect for the Software Development Organization at HCA. Responsible for the design and implementation of HCA's Enterprise API Management platform, 
          Unifed Messaging Platform (Responsible for 3 million messages per month in 2020), self-service tooling, development strategies, and consulting activities.`
        },
        {
          logo: require('./assets/logos/hca.jpg'),
          company: 'HCA Healthcare',
          timespan: 'Jul 2019 – Nov 2019',
          position: 'Senior Solution Architect',
          summary: `<p>Became the official architect of HCA's Notification Platform that is responsible for sending over 1 million messages per month in 2019. 
          Worked alongside the product owner of the platform, met with business stakeholders, and turned business requirements into technical 
          requirements and strategy. Lead the developers through sprints to deliver features on a 2-week cadence.</p><p>Managed our Windows 
          and Linux server environments, performed hardware refresh migrations, and authored tutorial videos and markdown documentation.</p>`
        },
        {
          logo: require('./assets/logos/hca.jpg'),
          company: 'HCA Healthcare',
          timespan: 'May 2018 – Jul 2019',
          position: 'Lead Application Developer',
          summary: `<p>Continued development and design of HCA's notification platform to send SMS, Email, Webex Teams, and secure messages to 
          patients, clinicians, and staff. The platform is responsible for sending over 1 million messages per month for a variety of use cases 
          include both clinical and administrative messaging.</p><p>Lead developer "round tables" to help increase collaboration between our department's 
          development teams. Additionally lead a book club discussing the DevOps handbook with different divisions in our company include infrastructure 
          and support to help create a more collaborative environment.</p><p>Architected an automation portal to help move our infrastructure and support 
          teams into a more self-service model for requests. This initiative is currently under development. It utilizes microservice based workers written 
          in .NET core to achieve different forms of automation (SSH, Ansible, REST calls). A Vue UI ties together an orchestration layer that will allow 
          infrastructure and support teams to create linear workflows with fail back steps, approval flows, and access controls.</p><p>Presented road-shows 
          of a POC I built with directors and vice presidents in HCA's Infrastructure Organization to demostrate the potential of an automation portal and secure buy-in and budget. 
          The project is now viewed as one of the most important projects by leadership in that organization.</p>`
        },
        {
          logo: require('./assets/logos/hca.jpg'),
          company: 'HCA Healthcare',
          timespan: 'Jul 2016 – May 2018',
          position: 'Senior Application Developer',
          summary: `<p>Member of the Data Innovation team which is a part of the larger Mobility Services & Data Integration Department. 
          I architected and build a microservice based notification platform with >30 C# services to send sms, email, cisco webex 
          teams messages, secure messages, and REST endpoint calls based on clinical rules. The platform is now the enterprise notification 
          platform and sends over 3 million messages per month that directly impact patient care and is credited with improving patient outcomes.</p>

          <p>Developed a FHIR server framework in C# through a collection of microservices and Nuget packages. The framework supports dynamic search parameters, 
          follows the FHIR spec for return types and operation outcomes.</p>

          <p>Created an Alexa, Google Home, and Cortana POC utilizing Microsoft’s bot framework, Amazon Alexa Dev Tools, Microsoft Research’s LUIS Tools, Google Cloud, 
          and C# for hospital rooms & at-home assistance around medication, notifications, appointments, among other use cases. Presented the POC to IT 
          leadership as well as at departmental all-hands events.</p>`
        },
        {
          logo: require('./assets/logos/hca.jpg'),
          company: 'HCA Healthcare',
          timespan: 'Jan 2015 – Jul 2016',
          position: 'Application Engineer II',
          summary: `<p>Member of the Architecture team that oversaw and approved development projects inside Parallon's BPG Department.</p>
          <p>Created a patient tracking web application with Marionette.JS, Backbone.JS, jQuery, Bootstrap, and ASP.NET MVC 5 to track patients 
          as they moved through a Patient Experience Tablet Check-In System.</p><p>Built Dashboards using D3.JS, jQuery, ASP.NET MVC 5 for the Patient 
          Accounting Open Access application.</p>`
        },
        {
          logo: require('./assets/logos/ut.png'),
          company: 'The University of Tennessee',
          timespan: 'Aug 2013 – Dec 2014',
          position: 'Electrical Engineering & Computer Science IT Staff',
          summary: `<p>I gained experience working on Ubuntu, CentOS, and Red Hat systems. I helped write CFEngine policy for our Linux systems, provisioned users 
          in OpenLDAP, helped manage our MySQL databases, created scripts for a variety of tasks, updated css/js/html on our department website, and debugged 
          numerous issues with our Linux systems.</p>

          <p>The other part of my job involved working with professors, students and staff to complete service requests. This involved communicating and explaining 
          problems in an easy to understand fashion. I also occasionally worked with students to address coding issues.</p>`
        }
      ],
    })
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

.heading {
  background-image: url("./assets/Updated.jpg");
  min-height: 100%;
  position: relative;
  opacity: 0.85;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

h1 {
  font-size: 50pt;
  color: #495464;
}

.cause-text {
  color: #495464 !important;
  font-size: 12pt;
  line-height: 1.6;
  padding: 15px;
}

.v-application--wrap {
  padding-left: 40px;
  padding-right: 40px;
}

.cause-image {
  margin: 15px;
}

p {
  padding: 5px;
}

.divider {
  padding-top: 20px;
}

.quote {
  padding: 5px;
  padding-left: 25px;
}

.contact {
  height: 150px;
  width: 150px;
}

.caption {
  right: 5%;
  top: 15%;
  position: absolute;
  display: inline-block;
  white-space: nowrap;
}

.work-summary {
  font-size: 12pt;
  line-height: 1.6;
  color: #495464;
}

body, html, #app {
  height: 100%;
  margin: 0;
  font-size: 400 15px/1.8;
  color: #262626;;
  display: block;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f2;
}

.border {
  background-color: #111;
  color: #fff;
  padding: 18px;
  margin: 15px;
  font-size: 25px;
  letter-spacing: 5px;
}
/* responsive, form small screens, use 13px font size */
@media (max-width: 479px) {
    .border {
        font-size: 13px;
    }
}

</style>
